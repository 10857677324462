@charset "UTF-8";
@media (max-width: 767px) {
  body::after {
    position: fixed;
    top: 0;
    left: 0;
    -webkit-transition: visibility 0.1s 0.25s, width 0.1s 0.25s, height 0.1s 0.25s, opacity 0.25s;
    transition: visibility 0.1s 0.25s, width 0.1s 0.25s, height 0.1s 0.25s, opacity 0.25s;
    z-index: 1040;
    visibility: hidden;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 0;
    height: 0;
    content: ''; }
  body.Page_Navigation-visible::after {
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
    visibility: visible;
    opacity: 1;
    width: 100%;
    height: 100%; } }

.Page_Navigation {

  -webkit-transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: left 0.3s ease-in-out, width 0.3s ease-in-out; }
.Page_Navigation .navbar-toggle {
  display: inline-block !important;
  float: none;
  margin-right: 5px;
  margin-left: 5px; }
.Page_Navigation .navbar-toggle .icon-bar {
  background-color: #fff; }
.Page_Navigation:not(.Page_Navigation--opened) .Page_Navigation-Submenu {
  display: none; }
@media (max-width: 767px) {
  .Page_Navigation {
    overflow: scroll;
    width: 270px;
    left: -270px; }
  .Page_Navigation-Menu{
    li{
      margin: 2px 0;
    }
  }
  .Page_Navigation-Submenu > li > a{
    padding: 5px 20px;
  }
  .Page_Navigation .Page_Navigation-Vendor {
    display: none; }
  .Page_Navigation .Page_Navigation-Menu {
    padding-top: 0; }
  .Page_Navigation.Page_Navigation--opened-mobile {

    left: 0; }
  .Page_Navigation .Page_Navigation-Submenu-opened-mobile .Page_Navigation-Submenu {
    display: block !important;
    left: 0px;
    z-index: 2;
  }
  }

@media (max-width: 991px) {
  .Header__Welcome__Inner .Header__Welcome__Clinic .clinic-select {
    width: 400px; } }

.main-header .navbar-toggle {
  z-index: 1;
  float: left;
  margin: 4px 0 3px 15px; }

header.main-header {
  position: fixed; }

@media (max-width: 767px) {
  header.main-header {
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
    background: #fff;
    height: 41px;
    min-height: 0;
    color: #333; }
  header.main-header > .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
    border: none;
    padding-left: 75px; }
  .main-content {
    padding-top: 41px; }
  section.main-content > .breadcrumb {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding-left: 15px; } }
@media (max-width: 767px) and (max-width: 767px) {
  section.main-content > .breadcrumb .navbar-collapse {
    border-radius: 6px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }
  section.main-content > .breadcrumb .navbar {
    right: 15px; }
  section.main-content > .breadcrumb .navbar .navbar-nav li a {
    padding-right: 20px;
    padding-left: 20px; }
  section.main-content > .breadcrumb .navbar .navbar-nav li.active a,
  section.main-content > .breadcrumb .navbar .navbar-nav li.active a:focus,
  section.main-content > .breadcrumb .navbar .navbar-nav li.active a:hover,
  section.main-content > .breadcrumb .navbar .navbar-nav li a:active,
  section.main-content > .breadcrumb .navbar .navbar-nav li a:focus,
  section.main-content > .breadcrumb .navbar .navbar-nav li a:hover {
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); } }

@media (max-width: 767px) {
  section.main-content > .content {
    padding-left: 15px; } }

.Contingents-Quickview .Contingents-Quickview-Controls {
  white-space: nowrap; }

@media (max-width: 1199px) {
  .Contingents-Quickview .Contingents-Quickview-Date {
    line-height: 28px; } }

@media (max-width: 1199px) {
  .Contingents-Quickview .Contingents-Quickview-Control {
    width: 28px;
    height: 28px; } }

table.dataTable,
div.dataTables_scrollHeadInner {
  width: 100% !important; }

@media (max-width: 767px) {
  .DataTable tbody td,
  .DataTable thead th {
    padding: 5px 10px 10px; } }

@media (max-width: 767px) {
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_desc_disabled:after {
    bottom: 10px !important; } }

@media (max-width: 767px) {
  .dataTables_wrapper .dataTables_filter input {
    width: 100% !important; } }

@media (max-width: 767px) {
  .Counselling_Message-Content .Counselling_Message-Body,
  .Counselling_Message-Content .Counselling_Message-Controls {
    padding-right: 0;
    width: 100%; }
  .Counselling_Message-Content .Counselling_Message-Controls {
    margin-bottom: 10px; } }

.News_List .News_List-Headline {
  padding-right: 100px; }

.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_paginate {
  margin-top: -1px !important; }

div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: 1px solid #e2e2e2 !important; }

.Documents .dataTables_filter > select {
  min-width: 200px; }

@media (max-width: 767px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0 !important; }
  .dataTables_wrapper .dataTables_info {
    padding-top: 8px !important;
    padding-right: 20px;
    padding-bottom: 8px; }
  .dataTables_wrapper .dataTables_paginate {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-right: 5px !important;
    padding-bottom: 5px;
    padding-left: 6px;
    height: auto !important;
    overflow: hidden;
    line-height: 1; }
  .dataTables_wrapper .pagination > li a {
    margin-bottom: -1px;
    padding: 10px 14px; }
  .dataTables_wrapper .pagination > li:first-child > a,
  .dataTables_wrapper .pagination > li:first-child > span {
    margin-left: -1px;
    border-bottom-left-radius: 0; }
  .dataTables_wrapper .pagination > li:last-child > a,
  .dataTables_wrapper .pagination > li:last-child > span {
    border-right: 1px solid #ddd;
    border-bottom-right-radius: 0; } }

@media (max-width: 767px) {
  .user-nav .dropdown {
    position: static; }
  .user-nav .dropdown .dropdown-menu {
    right: 0;
    left: auto;
    margin-right: 5px;
    margin-left: 5px;
    max-width: 100%;
    min-width: 0;
    max-height: calc(100vh - 55px);
    overflow-y: auto; }
  .avatar > img {
    width: 20px;
  }
}

@media (min-width: 768px) {
  .user-nav .dropdown > a {
    padding-top: 30px;
    padding-bottom: 30px; }
  .user-nav .dropdown > .avatar {
      padding-top: 12px;
      padding-bottom: 10px; }
  .avatar > img {
    width: 60px;
  }
  }

header.main-header .user-nav {
  margin: 0;
  padding: 0 10px; }
@media (max-width: 767px) {
  header.main-header .user-nav {
    padding-right: 0; } }

.Navbar__Dropdown__List .notification-message {
  padding-right: 30px; }

@media (max-width: 767px) {
  .Client__File {
    padding-top: 30px !important;
    padding-right: 15px !important;
    padding-left: 15px !important; }
  .Client__File .Client__File__Toggle {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); }
  .Client__File .Client__File__User {
    position: static; }
  .Client__File .Client__File__Hint {
    margin-top: 10px;
    margin-bottom: 10px; }
  .Client__File .nav-tabs {
    margin-right: -15px !important;
    margin-left: -15px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap; }
  .Client__File .nav-tabs > li {
    display: inline-block;
    float: none; }
  .Client__File .nav-tabs > li:first-child {
    margin-left: 15px; }
  .Client__File .nav-tabs > li:last-child {
    margin-right: 15px; }
  .Client__File .nav-tabs > li > a {
    border: 1px solid #ddd;
    padding-right: 10px;
    padding-left: 10px; }
  .Client__File .Client__File__Tab_Pane {
    padding-right: 0; }
  .Client__File .Client__File__Controls {
    margin-bottom: 15px; }
  .Client__File .Client__File__Control {
    margin-right: 5px;
    margin-bottom: 5px; } }

@media (min-width: 768px) and (max-width: 1280px) {
  .side-col {
    padding-left: 0; } }

@media (min-width: 768px) and (max-width: 1280px) {
  .side-col .absence-manager .absence-manager-date {
    font-size: 12px; }
  .side-col .absence-manager .absence-manager-date .form-control {
    font-size: 12px; } }

@media (max-width: 768px) {
  .side-col .absence-manager .absence-manager-date.form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; } }

@media (min-width: 768px) and (max-width: 1280px) {
  .side-col .absence-manager .absence-manager-toolbar .btn {
    padding-right: 2px;
    padding-left: 2px;
    font-size: 12px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .side-col .absence-manager .absence-manager-toolbar .btn-group {
    display: block;
    float: none;
    width: auto; }
  .side-col .absence-manager .absence-manager-toolbar .btn-group .btn {
    border-radius: 0; } }

.Forum-Thread-Additional {
  padding: 10px 30px 10px 0;
  min-width: 0;
  color: #aaa; }
.Forum-Thread-Additional ul li {
  white-space: nowrap; }
@media (max-width: 767px) {
  .Forum-Thread-Additional {
    display: block;
    padding-top: 0;
    padding-right: 15px;
    padding-left: 15px; }
  .Forum-Thread-Additional ul li {
    display: inline-block;
    margin-right: 30px; }
  .Forum-Thread-Additional ul li:last-child {
    margin-right: 0; } }

.Forum-Thread-Info {
  padding-right: 30px; }
@media (max-width: 767px) {
  .Forum-Thread-Info {
    padding-bottom: 10px; } }

.Forum-Board-Threads,
.Forum-Board-Posts,
.Forum-Board-Latest,
.Forum-Thread-Author,
.Forum-Thread-Latest,
.Forum-Thread-Period {
  display: inline;
  margin: 0;
  line-height: 20px;
  float: none; }

.Forum-Board-Threads:after,
.Forum-Board-Posts:after,
.Forum-Thread-Author:after,
.Forum-Thread-Latest:after {
  content: "• ";
  display: inline;
  margin: 0 2px;
  opacity: 0.5; }

@media (max-width: 767px) {
  .Forum-Thread-Icon {
    min-width: 60px; } }

@media (max-width: 767px) {
  .Forum-Board-Title,
  .Forum-Thread-Title {
    font-size: 1.25em; } }
